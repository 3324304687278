/* global graphql */
import React from 'react';
import TemplateWrapper from '../../components/TemplateWrapper';
import HeaderExp from '../../components/header/header-experiences';
import InfoSectionExp from '../../components/InfoSection/InfoSectionExp';
import RainbowCTA from '../../components/RainbowCTA';
import {Link} from "gatsby";
import { graphql } from "gatsby";

const ExpEnterprisePage = props =>
  (<TemplateWrapper location={props.location} metadata={props.data.allDataJson.edges[0].node.ExpEntMeta}>
    <HeaderExp location={props.location.pathname} />
    <main className="ExperiencesPage ExpEnterprisePage">
      <section className="Section ExperiencesPage-heading">
        <div className="container-fluid">
          <div className="wrapper">
            <div className="row">
              <div className="col-xs-14 col-md-6 col-md-offset-1 ExperiencesPage-heading-title">
                <h1 className="Heading Heading-d1 light">Enterprise</h1>
              </div>
              <div className="col-xs-14 col-md-6 ExperiencesPage-heading-cta">
                <Link to="/waitlist/" className="BtnFilled BtnFilled-large purple uppercase" onClick={()=>window.analytics.track('Clicked Waitlist CTA',{component:'ExpEnterprisePage'})}><span>Sign up</span></Link>
                <p className="Caption Caption-C3 light uppercase">Available in 2019</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="Section ExperiencesPage-info1 ExpEnterprisePage-info1">
        <div className="container-fluid">
          <div className="wrapper">
            <div className="row">
              <div className="col-xs-14 col-md-12 col-md-offset-1">
                <hr className="ExperiencesPage-heading-hr" />
              </div>
              <div className="col-xs-14 col-md-5 hide-xs hide-sm">
                <img src="https://s3.us-east-2.amazonaws.com/hologram-static/magic/marketing/expenterprise_tractor.png" alt="cellular agriculture"/>
              </div>
              <div className="col-xs-14 col-md-7 col-md-offset-1">
                <InfoSectionExp data={props.data.allDataJson.edges[0].node.ExpEntInfo1} />
              </div>
              <div className="col-xs-14 col-md-5 col-md-offset-1 hide-md hide-lg hide-xl">
                <img src="https://s3.us-east-2.amazonaws.com/hologram-static/magic/marketing/expenterprise_tractor.png" alt="cellular agriculture"/>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="Section ExperiencesPage-info2">
        <div className="container-fluid">
          <div className="wrapper">
            <div className="row">
              <div className="col-xs-14 col-md-7 col-md-offset-1">
                <hr className="ExperiencesPage-heading-hr" />
                <InfoSectionExp data={props.data.allDataJson.edges[0].node.ExpEntInfo2} />
              </div>
              <div className="col-xs-14 col-md-4 col-md-offset-1">
                <img src="https://s3.us-east-2.amazonaws.com/hologram-static/magic/marketing/expent_network.png" alt="network"/>
              </div>
            </div>
          </div>
        </div>
      </section>
      <RainbowCTA title="What are you waiting for?"/>
    </main>
  </TemplateWrapper>);

export default ExpEnterprisePage;


export const pageQuery = graphql`
  query ExpEnterpriseQuery {
    allDataJson {
      edges {
        node {
          ExpEntMeta {
            title,
            description
          }
          ExpEntInfo1 {
            title,
            subtitle,
            paragraph1,
            paragraph2,
            callout,
            paragraph3
          }
          ExpEntInfo2 {
            title,
            subtitle,
            paragraph1,
            paragraph2,
            callout,
            paragraph3
          }
        }
      }
    }
  }
`;
