import React from 'react';


const InfoSectionExp = props =>
  (<div className="InfoSectionExp">
    <h2 className="Heading Heading-h2 light InfoSectionExp-title">{props.data[0].title}</h2>
    {props.data[0].subtitle && <p className="Heading Heading-subheader light uppercase font-mono-regular InfoSectionExp-subtitle">{props.data[0].subtitle}</p>}
    <p className="BodyText BodyText-B1 light InfoSectionExp-p">{props.data[0].paragraph1}</p>
    {props.data[0].paragraph2 &&  <p className="BodyText BodyText-B1 light InfoSectionExp-p InfoSectionExp-p-2">{props.data[0].paragraph2}</p>}
    <h3 className="Heading Heading-h3 light font-medium InfoSectionExp-callout">
      { props.data[0].callout === "custom" ?
        <div>
          <span>>> How are you online?</span>
          <span className="color-pink">>> It's Magic!</span>
        </div> :
        props.data[0].callout
    }</h3>
    <p className="BodyText BodyText-B1 light InfoSectionExp-p">{props.data[0].paragraph3}</p>
  </div>);

export default InfoSectionExp;
